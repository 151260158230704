<template>
  <div>
    <img style="width: 100%" alt="" src="@/assets/images/patientInfo.png" />
    <div class="ml-10 mr-10">
      <h2 v-show="doctorModel.Next != true">
        {{ $t("Pleasecompleteyourregistrationtoaccessourservices") }}
      </h2>
      <MainInfo :doctorModel="doctorModel" v-show="doctorModel.Next != true" />
      <div class="w-full" v-show="doctorModel.Next != true">
        <div class="justify-right">
          <vs-button
            @click="changeMode()"
            color="rgb(62, 201, 214)  "
            type="filled"
            style="float: right"
            >{{ $t("Next") }}</vs-button
          >
        </div>
      </div>
      <DoctorMoreInfo
        :doctorModel="doctorModel"
        v-show="doctorModel.Next == true"
      />
      <div class="w-full" v-show="doctorModel.Next == true">
        <div class="justify-right">
          <vs-button
            @click="submitData()"
            :disabled="!isFormValid"
            color="rgb(62, 201, 214)"
            type="filled"
            style="float: right"
            >{{ $t("Submit") }}</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainInfo from "@/views/doctorProfile/MInInfoo.vue";
import DoctorMoreInfo from "@/views/doctorProfile/DoctorMoreInfo.vue";
import moduleCity from "@/store/settings/city/moduleCity.js";

import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
 import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";

export default {
  components: {
    MainInfo,
    DoctorMoreInfo,
  },
  data() {
    return {
      doctorModel: {
        specialities: [],
        DoctorSpecialities: [],
        PatientMedicalHistory: {},
        Next: false,
      },
    };
  },
  methods: {
    changeMode() {
      debugger;
      this.doctorModel.Next = true;
    },
    getDoctorData() {
      debugger;
      this.$vs.loading();

      this.$store
        .dispatch("DoctorList/GetDoctor", this.$route.params.ID)
        .then((res) => {
          this.doctorModel = res.data.Data;
          this.doctorModel.specialities =
            this.doctorModel.DoctorSpecialities.map((a) => a.SpecialtyID);
          this.$store.commit(
            "DoctorList/SET_specialities",
            this.doctorModel.specialities
          );
          this.$set(this.doctorModel, "Next", false);
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    submitData() {
      this.doctorModel.DoctorSpecialities =
        this.$store.state.DoctorList.specialities.map(function (val) {
          var obj = {};
          obj.SpecialtyID = val;
          return obj;
        });
      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/UpdateDoctor", this.doctorModel)
        .then((res) => {
        
          this.$vs.loading.close();
          this.$router.push({ name: "page-login" });
          window.showSuccess("please wait till you confirm your account, you  will notify by mail");
        })
        .catch((err) => {
          this.$vs.loading.close();
          window.showError(err.response.data.message);
          console.error(err);
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },
  computed: {
    isFormValid() {
      debugger
      return !this.errors.any() && this.doctorModel && this.doctorModel.CountryID&& this.doctorModel.CountryID!=null
       &&this.doctorModel.NameEN && this.doctorModel.FamilyName 
       && this.doctorModel.FullName&& this.doctorModel.TitleEN ;
    },
    // ListOfSpecialities() {
    //   return this.$store.state.SpecialtyList.specialties;
    // },
  },
  created() {
    debugger
    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }

    this.$store.dispatch("RegionList/GetAllRegions");
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }

    this.$store.dispatch("CityList/GetAllCities");
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    this.$store.dispatch("SpecialtyList/GetAllSpecialties");

    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    this.$store.dispatch("CountryList/GetAllCountries");
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.getDoctorData();
  },
};
</script>
<style>
</style>
